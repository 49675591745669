import { defineStore } from 'pinia';
import { apolloClient } from '@utils/apollo';
import { useDepartmentStore } from '@stores/department';
import { format } from '@utils/date-fns';
import { get } from 'lodash';
import { bookingResourcesQuery } from '@graphql/queries/bookingResources.js';
import { bookingSlotsQuery } from '@graphql/queries/bookingSlots.js';
import { bookResourceScreenMutation } from '@graphql/mutations/bookResourceScreen.js';
import { bookSlottedResourceScreenMutation } from '@graphql/mutations/bookSlottedResourceScreen.js';
import { cancelSlottedBookingScreenMutation } from '@graphql/mutations/cancelSlottedBookingScreen.js';
import { getAllResourceBookings } from '@utils/bookings';
import { endOfWeek, startOfWeek } from 'date-fns';
import { useProfilesStore } from '@stores/profiles';

export const useBookingResourcesStore = defineStore('bookingResources', {
  state: () => ({
    all: [],
    resourceBookings: null,
    selectedBookingEntry: {},
    selectedBookingResource: {},
    selectedBookingSlots: [],
    selectedSlottedWeek: {
      weekStart: null,
      weekEnd: null,
    },
    opt: null,
    newBooking: {
      selectedStartDate: '',
      selectedStartTime: '',
      selectedEndDate: '',
      selectedEndTime: '',
      title: '',
      resourceId: null,
    },
    newSlottedBooking: {
      participants: [],
      bookingSlot: {
        bookingResourceId: null,
        bookingResourceSlotId: null,
        date: '',
      },
    },
  }),
  actions: {
    async getBookingResources() {
      const departmentStore = useDepartmentStore();

      if (!departmentStore.id) {
        throw new Error('No department id provided');
      }

      await apolloClient
        .query({
          query: bookingResourcesQuery,
          variables: {
            filter: {
              departments: departmentStore.id,
            },
            timezone: 'Europe/Copenhagen',
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          const resources = response.data.bookingResources;

          this.all = resources;
          this.resourceBookings = getAllResourceBookings(resources);
        })
        .catch((error) => {
          console.log('Error getting bookings', error);
        });
    },
    async getBookingSlots({ id, dateFrom, dateTo }) {
      await apolloClient
        .query({
          query: bookingSlotsQuery,
          variables: {
            bookingResourceId: id,
            dateFrom,
            dateTo,
            timezone: 'Europe/Copenhagen',
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          this.selectedBookingSlots = response.data.bookingSlots;
        })
        .catch((error) => {
          console.log(`Error getting bookingSlots from id ${id}`, error);
        });
    },
    setSelectedBookingEntry(bookingEntry) {
      this.selectedBookingEntry = bookingEntry;
    },
    setSelectedBookingResource(bookingResource) {
      this.selectedBookingResource = bookingResource;
    },
    setSelectedSlottedWeek(week) {
      if (!week) {
        const currentWeek = {
          weekStart: startOfWeek(new Date(), { weekStartsOn: 1 }),
          weekEnd: endOfWeek(new Date(), { weekStartsOn: 1 }),
        };

        this.selectedSlottedWeek = currentWeek;
        return;
      }
      this.selectedSlottedWeek = week;
    },
    setNewBooking(booking) {
      this.$patch({
        newBooking: {
          ...this.newBooking,
          ...booking,
        },
      });
    },
    setNewSlottedBooking(booking) {
      this.newSlottedBooking = booking;
    },
    async createNewBooking() {
      const participants = this.newBooking.participants.map((participant) => {
        return {
          id: participant.id,
          type: participant.type,
        };
      });

      const now = new Date();
      const timezone = format(now, 'XXX');
      const end = `${this.newBooking.selectedEndDate}T${this.newBooking.selectedEndTime}:00${timezone}`;
      const start = `${this.newBooking.selectedStartDate}T${this.newBooking.selectedStartTime}:00${timezone}`;

      const booking = {
        bookingResourceId: this.newBooking.resourceId,
        end,
        start,
        title: this.newBooking.title,
      };

      let newBookingId;

      await apolloClient
        .mutate({
          mutation: bookResourceScreenMutation,
          variables: {
            participants,
            booking,
          },
        })
        .then(async (response) => {
          newBookingId = get(
            response,
            'data.booking.bookResourceScreen.id',
            undefined
          );

          if (!newBookingId)
            throw new Error('Error creating booking. No bookingId provided');
        })
        .catch((error) => {
          throw new Error('Error saving new booking', error);
        });

      return newBookingId;
    },
    async setOpt(opt) {
      this.opt = opt;
    },
    async createNewSlottedBooking() {
      const profilesStore = useProfilesStore();
      const activeProfile = profilesStore.activeProfile;

      await apolloClient
        .mutate({
          mutation: bookSlottedResourceScreenMutation,
          variables: {
            participants: [
              {
                id: activeProfile.id,
                type: activeProfile.type,
              },
            ],
            bookingSlot: this.newSlottedBooking.bookingSlot,
          },
        })
        .then(() => {
          profilesStore.setPinValidation({});
        })
        .catch((error) => {
          throw new Error(`Error creating slotted booking', ${error}`);
        });
    },
    async cancelSlottedBooking() {
      const profilesStore = useProfilesStore();

      await apolloClient
        .mutate({
          mutation: cancelSlottedBookingScreenMutation,
          variables: {
            profile: this.newSlottedBooking.participants[0],
            bookingSlot: this.newSlottedBooking.bookingSlot,
          },
        })
        .then(() => {
          profilesStore.setPinValidation({});
        })
        .catch((error) => {
          profilesStore.setPinValidation({
            validating: false,
            validPin: false,
          });

          throw new Error('Error cancelling booking', error);
        });
    },
  },
});
