<script setup>
import {
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
  onBeforeMount,
} from 'vue';
import Timeline from '@components/workSchedule/Timeline.vue';
import Shift from '@components/workSchedule/Shift.vue';
import DaySelector from '@components/workSchedule/DaySelector.vue';
import { addDays, subDays, isSameDay } from 'date-fns';
import { format } from '@utils/date-fns';
import { get } from 'lodash';
import { default as ButtonEl } from '@components/shared/Button.vue';
import EmptyViews from '@views/shared/EmptyViews.vue';
import { useRoute } from 'vue-router';
import { useInstitutionStore } from '@stores/institution';
import { useGeneralStore } from '@stores/general';
import { useDepartmentStore } from '@stores/department';
import { useProfilesStore } from '@stores/profiles';
import { useWorkScheduleStore } from '@stores/workSchedule';
import { useCacheStore } from '@stores/cache';

const institutionStore = useInstitutionStore();
const generalStore = useGeneralStore();
const departmentStore = useDepartmentStore();
const profilesStore = useProfilesStore();
const workScheduleStore = useWorkScheduleStore();
const cache = useCacheStore();
const route = useRoute();

const currentTime = ref('00:00');
const interval = ref({});
const scrollEventHandler = ref();
const times = [
  '',
  '',
  '',
  '',
  '',
  '',
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '00',
  '',
  '',
];

const offsetLeft = ref(0);
const isOutOfBounds = ref(false);
const selectedDay = ref(new Date());
const hourWidth = 90;
const today = new Date();

const workSchedules = computed(() => workScheduleStore.all);
const pinValidation = computed(() => profilesStore.pinValidation);
const editAction = computed(() => workScheduleStore.editAction);
const selectedShift = computed(() => workScheduleStore.selectedShift);

const moduleColor = computed(() => {
  return route.meta.color;
});

const shownDays = computed(() => {
  // If the department has checked overwrite institution setting it will be in the shift array.
  const matchingDepartment = institutionStore.settings.shift?.find(
    ({ id }) => id == departmentStore.id
  );

  return (
    get(
      matchingDepartment,
      'daysForward',
      departmentStore.settings.shift.daysForward
    ) - 1 // Subtract 1 because the core admin setting is not 0 based but 1 should mean 1 days forward.
  );
});

const shifts = computed(() => {
  if (workSchedules.value.length) scrollIntoPlace();
  return workSchedules.value.filter((schedule) => {
    return (
      isSameDay(new Date(schedule.start), selectedDay.value) ||
      isSameDay(new Date(schedule.end), selectedDay.value)
    );
  });
});

const viewWidth = computed(() => {
  return `${hourWidth * times.length}px`;
});

const isToday = computed(() => {
  return isSameDay(today, selectedDay.value);
});

const dateInterval = computed(() => {
  return {
    from: subDays(today, shownDays.value - 1),
    to: addDays(today, shownDays.value - 1),
  };
});

watch(pinValidation, (pinData) => {
  if (!pinData.validPin || pinData.validating) return;

  if (editAction.value === 'cancel') {
    workScheduleStore.shiftUpdate({
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
      cancel: true,
    });
  } else if (editAction.value === 'reactivate') {
    workScheduleStore.shiftUpdate({
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
      cancel: false,
    });
  }

  if (editAction.value === 'replace') {
    workScheduleStore.setReplaceWithEmployeeId(
      generalStore.activeOverlay.data.employee.id
    );

    workScheduleStore.shiftUpdate({
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
      cancel: false,
    });
  }

  if (editAction.value === 'delete') {
    workScheduleStore.shiftDelete({
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
    });
  }
});

watch(workSchedules, () => {
  if (!selectedShift?.value?.id) return;

  shifts.value.find((shift) => {
    if (shift.id === selectedShift.value.id) {
      workScheduleStore.setSelectedShift(shift);

      generalStore.setActiveOverlay({
        name: 'work-schedule-entry',
        data: { ...shift },
      });
    }
  });
});

watch(selectedDay, () => {
  document.querySelector('.schedule').scrollLeft += 1;
});

onBeforeMount(() => {
  cache.use(
    'workScheduleStore.getWorkSchedules',
    workScheduleStore.getWorkSchedules
  );
});

onMounted(() => {
  isOutOfBounds.value = false;
  const secondsUntilNextMinute = (60 - format(today, 'ss')) * 1000;
  setCurrentTime();
  setOffsetLeft();

  scrollEventHandler.value = (event) => {
    isOutOfBounds.value =
      event.target.scrollLeft > offsetLeft.value ||
      event.target.scrollLeft + window.innerWidth < offsetLeft.value;
  };
  setTimeout(() => {
    const scheduleElement = document.querySelector('.schedule');
    if (scheduleElement) {
      scheduleElement.addEventListener('scroll', scrollEventHandler.value);
    }
  }, 1000);

  setTimeout(() => {
    setCurrentTime();
    interval.value = setInterval(() => {
      setCurrentTime();
      setOffsetLeft();
    }, 60000);
  }, secondsUntilNextMinute);

  setTimeout(() => {
    scrollToNow();
  }, 200);
});

onUnmounted(() => {
  clearInterval(interval.value);
  const scheduleElement = document.querySelector('.schedule');
  if (scheduleElement) {
    scheduleElement.removeEventListener('scroll', scrollEventHandler.value);
  }
});

function setCurrentTime() {
  currentTime.value = format(today, 'HH:mm');
}

function showSingleShift(shift) {
  workScheduleStore.setSelectedShift(shift);

  setTimeout(() => {
    generalStore.setActiveOverlay({
      name: 'work-schedule-entry',
      data: shift ? { ...shift } : { ...selectedShift.value },
    });
  }, 400);
}

function setOffsetLeft() {
  const split = format(today, 'H:mm').split(':');
  const hours = parseInt(split[0]) + 6;
  const minutes = hours * 60 + parseInt(split[1]);
  const minuteWidth = window.innerWidth / 12 / 60;
  offsetLeft.value = parseInt(minutes) * minuteWidth + 10;
}

function scrollToNow() {
  if (!shifts.value.length) return;
  document.querySelector('.indicator').scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  });
}

function scrollToInterval() {
  // Based on user input the screen should scroll to the 06 - 17 interval by default on future and past days.
  document.querySelector('.schedule').scroll({
    behavior: 'smooth',
    left: 1060,
    top: 0,
  });
}

function scrollIntoPlace() {
  setTimeout(() => {
    if (isSameDay(today, selectedDay.value)) {
      scrollToNow();
    } else {
      scrollToInterval();
    }
  }, 200);
}

function handleSelectedDay(payload) {
  selectedDay.value = payload;
  scrollIntoPlace();
}
</script>

<template>
  <div class="flex flex-col h-full">
    <div class="overflow-x-auto schedule relative h-full z-10">
      <div
        class="flex flex-col overflow-x-scroll h-full absolute"
        :style="{ width: viewWidth }"
      >
        <div class="w-full h-full absolute z-10 inline-flex">
          <div
            v-for="index in times.length"
            :key="index"
            class="h-full"
            :style="{ 'flex-basis': `${hourWidth}px` }"
          >
            <div class="w-6 h-full border-r border-charcoal-xxlight" />
          </div>
        </div>

        <div
          class="overflow-y-scroll overflow-x-none flex flex-col relative h-full"
        >
          <Timeline
            class="w-max border-b-2 border-charcoal-xxlight fit-content z-20 bg-white"
            :times="times"
          />

          <div
            v-if="isToday && shifts.length"
            class="flex flex-col space-between w-2 absolute h-full z-30"
            :style="{ marginLeft: `${offsetLeft}px` }"
          >
            <div
              class="bg-charcoal w-20 h-12 mt-6 flex items-center justify-center rounded-lg relative -ml-9 shadow-bottomRight"
            >
              <p class="text-white text-2xl">
                {{ currentTime }}
              </p>
            </div>

            <div class="w-2 h-full">
              <div
                class="shadow-bottomRight indicator bg-charcoal w-full h-full"
              />
            </div>

            <div
              class="bg-charcoal w-20 h-12 mb-6 flex items-center justify-center rounded-lg relative -ml-9 shadow-bottomRight"
            >
              <p class="text-white text-2xl">
                {{ currentTime }}
              </p>
            </div>
          </div>

          <EmptyViews
            v-if="!shifts.length"
            view-type="work_schedule"
            :color="moduleColor"
          />

          <div class="flex flex-row relative w-full overflow-x-hidden h-full">
            <div
              class="overflow-y-scroll flex flex-col items-center justify-center z-30"
              :class="{ absolute: shifts.length > 5 }"
            >
              <Shift
                v-for="(shift, index) in shifts"
                :key="index"
                :index="index"
                :shift="shift"
                :moduleColor="moduleColor"
                :viewWidth="viewWidth"
                :selectedDay="selectedDay"
                typeOfShift="normal"
                @click="showSingleShift(shift)"
              />
            </div>
          </div>
          <Timeline
            class="w-max border-t-2 border-charcoal-xxlight fit-content z-20 bg-white"
            :times="times"
            :style="{ width: viewWidth }"
          />
        </div>
      </div>
    </div>

    <div
      v-if="isToday"
      class="bottom-1/3 w-full fixed -mb-6 flex items-center justify-center z-20"
    >
      <ButtonEl
        v-if="isOutOfBounds && shifts.length > 0"
        text="global.backToNow"
        icon="angle-right"
        background-color="charcoal"
        @click="scrollToNow()"
      />
    </div>

    <DaySelector @handleSelectedDay="handleSelectedDay($event)" class="mb-6" />
  </div>
</template>

<style lang="scss">
.backToNow {
  bottom: 28vh;
}
</style>
