import gql from 'graphql-tag';

export const activitySignupScreenMutation = gql`
  mutation activitySignupScreen(
    $profile: ScreenProfile!
    $subscription: ActivitySubscription!
  ) {
    activitySignupScreen(profile: $profile, subscription: $subscription) {
      id
      success
      message
    }
  }
`;
