<script setup>
const emit = defineEmits(['show-people']);

defineProps({
  profiles: {
    type: Array,
    default: () => [],
  },
  text: {
    type: String,
    default: 'global.participantsPlural',
  },
  icon: {
    type: String,
    default: 'angle-right',
  },
});
</script>

<template>
  <div
    class="relative content items-center w-full flex"
    @click="emit('show-people')"
  >
    <div class="flex flex-1 mb-8">
      <fa-icon class="mr-6" :icon="['fal', 'user-friends']" />

      <p class="flex-1" :aria-label="$t(text, profiles.length)">
        {{ profiles.length }} {{ $t(text, profiles.length) }}
      </p>
    </div>

    <fa-icon v-if="icon" class="text-5xl" :icon="['fal', icon]" />
  </div>
</template>
