import gql from 'graphql-tag';

export const archiveAddScreenMutation = gql`
  mutation archiveAddScreen($profile: ScreenProfile!, $resourceId: ID!) {
    archiveAddScreen(profile: $profile, resourceId: $resourceId) {
      id
      success
      message
    }
  }
`;
