import gql from 'graphql-tag';

export const archiveRemoveScreenMutation = gql`
  mutation archiveRemoveScreen($profile: ScreenProfile!, $resourceId: ID!) {
    archiveRemoveScreen(profile: $profile, resourceId: $resourceId) {
      id
      success
      message
    }
  }
`;
