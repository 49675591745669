<script setup>
import { getModuleData } from '@utils/modules';
import { onBeforeMount, ref } from 'vue';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';
import { useInstitutionStore } from '@stores/institution';
import { useMealsStore } from '@stores/meals';
import { useCacheStore } from '@stores/cache';

const institutionStore = useInstitutionStore();
const mealsStore = useMealsStore();
const cache = useCacheStore();

const isLoading = ref(true);

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().MEAL_PLAN;

onBeforeMount(async () => {
  if (institutionStore.settings.useExternalMeals) {
    await cache.use('mealsStore.getPcdMeals', mealsStore.getPcdMeals);
  } else {
    await cache.use('mealsStore.getMeals', mealsStore.getMeals);
  }

  isLoading.value = false;
});
</script>

<template>
  <ModuleBaseView
    v-if="!isLoading"
    :moduleName="emptyKey"
    :moduleData="mealsStore.all"
    :colorHex="colorHex"
    :icon="icon"
    :emptyKey="emptyKey"
    :routeName="routeName"
    :entryRouterName="entryRouterName"
  />
</template>
