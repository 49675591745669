import i18n from '@i18n';
import { useGeneralStore } from '@stores/general';

export default class PinFlow {
  constructor(options) {
    this.entity = options.entity;
    this.text = options.text;
    this.opt = options.opt;
    this.action = options.action;
    this.title = options.title;
    this.employeeName = options.employeeName;
    this.employee = options.employee;
    this.participants = options.participants;
    this.sharedDepartments = options.sharedDepartments;
  }
  startSignupFlow() {
    const title =
      `${i18n.global.t('modals.writePassword')} ` +
      i18n.global.t(`modals.writePasswordOpt${this.opt}`) +
      ` ${i18n.global.t(this.text)}`;
    const titleSuccess = `${i18n.global.t(`global.${this.opt === 'in' ? 'youAreSignedUp' : 'youOptedOut'}`)} ${i18n.global.t(this.text)}`;

    const overlayData = {
      entity: this.entity,
      entityName: this.title,
      title,
      titleSuccess,
      opt: this.opt,
      sharedDepartments: this.sharedDepartments,
      participants: this.participants,
    };

    this.showOverlay(overlayData);
  }
  startCreateFlow(title, employeeOnly = false) {
    // Set employeeOnly to true and selectedProfileType to EMPLOYEE to only be able to select between employees, and remove the residents tab
    const overlayData = {
      entityName: this.title,
      title: `${i18n.global.t('modals.writePassword')} ${i18n.global.t(title)}`,
      employeeOnly,
      ...(employeeOnly && { selectedProfileType: 'EMPLOYEE' }), // Only add selectedProfileType if employeeOnly is true.
      sharedDepartments: this.sharedDepartments,
    };

    this.showOverlay(overlayData);
  }

  grantAccessToGallery() {
    const overlayData = {
      title:
        i18n.global.t('modals.writePassword') +
        i18n.global.t('gallery.galleryAccess'),
      entityName: this.title,
      closeOnSuccess: true,
      sharedDepartments: this.sharedDepartments,
    };

    this.showOverlay(overlayData);
  }

  startUpdateShiftFlow() {
    const title = `${i18n.global.t('modals.writePassword')} ${i18n.global.t('scheduleShift.' + this.action)} ${i18n.global.t('scheduleShift.shiftFor')} ${i18n.global.t(this.employeeName)}`;

    const overlayData = {
      title,
      action: this.action,
      closeOnSuccess: true,
      employeeOnly: true,
      selectedProfileType: 'EMPLOYEE',
      employee: this.employee,
      sharedDepartments: this.sharedDepartments,
    };

    this.showOverlay(overlayData);
  }

  showOverlay(overlayData) {
    const generalStore = useGeneralStore();

    generalStore.setActiveOverlay({
      name: 'profile-select',
      data: overlayData,
    });
  }
}
