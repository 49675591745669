<script setup>
import { default as ButtonEl } from '@components/shared/Button.vue';

const emit = defineEmits(['playVideo', 'pauseVideo', 'resetView']);
</script>

<template>
  <div class="flex flex-row items-center justify-between w-full px-32">
    <ButtonEl
      class="content bold"
      text="global.play"
      icon="play"
      backgroundColor="success"
      @click="emit('playVideo')"
    />

    <ButtonEl
      class="content bold"
      text="global.pause"
      icon="pause"
      backgroundColor="error"
      @click="emit('pauseVideo')"
    />

    <ButtonEl
      class="content bold"
      text="global.restart"
      icon="undo-alt"
      backgroundColor="warning"
      @click="emit('resetView')"
    />
  </div>
</template>
