import { defineStore } from 'pinia';
import { apolloClient } from '@utils/apollo';
import { useDepartmentStore } from '@stores/department';
import { GalleriesQuery } from '@graphql/queries/galleries.js';
import { GalleryEntryQuery } from '@graphql/queries/galleryEntry';
import { archiveAddScreenMutation } from '@graphql/mutations/archiveAddScreen.js';
import { archiveRemoveScreenMutation } from '@graphql/mutations/archiveRemoveScreen.js';
import { useProfilesStore } from '@stores/profiles';

export const useGalleriesStore = defineStore('galleries', {
  state: () => ({
    all: [],
    galleryEntry: {},
    categories: [],
    selectedCategory: 'AllCategoriesSelected',
  }),
  actions: {
    async getGalleries() {
      const departmentStore = useDepartmentStore();

      if (!departmentStore.id) {
        throw new Error('No department id provided');
      }

      await apolloClient
        .query({
          query: GalleriesQuery,
          variables: {
            filter: {
              departments: departmentStore.id,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          this.all = response.data.galleries;
        })
        .catch((error) => {
          console.log('Error getting galleries', error);
        });
    },
    async resetGalleryEntry() {
      this.galleryEntry = {};
    },
    async getGalleryEntry(id) {
      await apolloClient
        .query({
          query: GalleryEntryQuery,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          this.galleryEntry = response.data.galleryEntry;
        })
        .catch((error) => {
          console.log('Error getting gallery', error);
        });
    },
    async archiveAddScreen({ resourceId, profile, opt }) {
      const profilesStore = useProfilesStore();

      const mutation =
        opt === 'in' ? archiveAddScreenMutation : archiveRemoveScreenMutation;

      await apolloClient
        .mutate({
          mutation: mutation,
          variables: {
            resourceId,
            profile,
          },
        })
        .then(async () => {
          profilesStore.setPinValidation({
            validating: false,
            validPin: true,
          });

          profilesStore.getMyArchive({
            id: profile.profileId,
            type: profile.profileType,
          });
        })
        .catch((error) => {
          profilesStore.setPinValidation({
            validating: false,
            validPin: false,
          });
          throw new Error('Could not add to archive', error);
        });
    },
    setCategories(categories) {
      this.categories = categories;
    },
    setSelectedCategory(category) {
      this.selectedCategory = category;
    },
  },
});
