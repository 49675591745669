import { useDepartmentStore } from '@stores/department';
import axios from 'axios';

export default {
  async getDepartment(loginKey) {
    const RETRY_ATTEMPTS = 3;
    const DELAY_MS = 5000;
    const URL = `${import.meta.env.VITE_LOGIN_URL}/department/accesstoken`;

    // If GET is successfull return from loop.
    // If failed await a promise and resolve it after delay.
    for (let attempt = 1; attempt <= RETRY_ATTEMPTS; attempt++) {
      try {
        const { data } = await axios.get(URL, {
          headers: { Authorization: loginKey },
        });
        const { accessToken, id } = data;

        if (!accessToken) {
          throw new Error('No data found for department with loginKey');
        } else {
          localStorage.setItem(
            'department_data',
            JSON.stringify({ accessToken, id })
          );
          const store = useDepartmentStore();
          store.id = id;
          return; // If auth was successful leave the loop.
        }
      } catch (error) {
        // If auth failed pause and continue loop after delay.
        const accumulated_delay = DELAY_MS * attempt;
        console.error(
          `Auth failed with error: ${error.message}. Retrying in ${accumulated_delay}ms...`
        );
        if (attempt <= RETRY_ATTEMPTS) {
          await new Promise((res) => setTimeout(res, accumulated_delay));
        } else {
          console.error(
            'All authentication attempts failed with error:',
            error
          );
        }
      }
    }
  },
  getDepartmentData() {
    const departmentData = localStorage.getItem('department_data') || '';
    let parsedData;

    try {
      parsedData = JSON.parse(departmentData);
    } catch (error) {
      parsedData = { id: undefined, accessToken: undefined };
      throw new Error('Error getting department data', error);
    }

    return parsedData;
  },
  async authenticate() {
    let tempKey = sessionStorage.getItem('temp_login_key');

    // Handle case when session storage reads null value as string.
    if (tempKey === 'null') {
      tempKey = null;
    }

    const mainKey =
      new URLSearchParams(window.location.search).get('loginKey') ||
      sessionStorage.getItem('main_login_key');

    sessionStorage.setItem('main_login_key', mainKey);

    let loginKey = tempKey || mainKey;
    localStorage.setItem('login_key', loginKey);

    if (loginKey && loginKey.length) {
      await this.getDepartment(loginKey);
    } else {
      throw new Error('No loginKey provided');
    }
  },
};
