import { defineStore } from 'pinia';
import { apolloClient } from '@utils/apollo';
import { useDepartmentStore } from '@stores/department';
import constants from '@utils/constants';
import { addMonths } from 'date-fns';
import { format } from '@utils/date-fns';
import { meetingRangeQuery } from '@graphql/queries/meetingRange.js';
import { meetingEntryQuery } from '@graphql/queries/meetingEntry.js';
import { meetingSignupScreenMutation } from '@graphql/mutations/meetingSignupScreen.js';
import { meetingCancelSignupScreenMutation } from '@graphql/mutations/meetingCancelSignupScreen.js';
import { meetingSuggestMutation } from '@graphql/mutations/meetingSuggest.js';
import { useProfilesStore } from '@stores/profiles';

export const useMeetingsStore = defineStore('meetings', {
  state: () => ({
    all: [],
    selected: {},
    suggestion: '',
  }),
  actions: {
    async getMeetings() {
      const departmentStore = useDepartmentStore();

      if (!departmentStore.id) {
        throw new Error('No department id provided');
      }

      const today = new Date();

      await apolloClient
        .query({
          query: meetingRangeQuery,
          variables: {
            filter: {
              departments: departmentStore.id,
            },
            dateFrom: format(new Date(), 'yyyy-MM-dd'),
            dateTo: format(addMonths(today, 12), 'yyyy-MM-dd'),
            timezone: constants.timezone,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          this.all = response.data.meetingRange;
        })
        .catch((error) => {
          console.log('Error getting meetings', error);
        });
    },
    async getSelectedMeeting(meetingId) {
      this.selected = {};

      await apolloClient
        .query({
          query: meetingEntryQuery,
          variables: {
            id: meetingId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          const meetingEntry = response.data.meetingEntry;

          const updatedResponse = {
            data: {
              meetingEntry: {
                ...meetingEntry,
                participants: meetingEntry.participants.map((participant) => {
                  if (!participant.showImage)
                    participant.displayImage = constants.avatarPlaceholder;
                  return participant;
                }),
                suggestions: meetingEntry.suggestions.map((suggestion) => {
                  if (!suggestion.suggester.showImage)
                    suggestion.suggester.displayImage =
                      constants.avatarPlaceholder;
                  return suggestion;
                }),
              },
            },
          };

          this.selected = updatedResponse.data.meetingEntry;
        })
        .catch((error) => {
          console.log('Could not get meeting entry: ', error);
        });
    },
    async meetingSignupScreen({ subscription, profile, opt }) {
      const profilesStore = useProfilesStore();

      const mutation =
        opt === 'in'
          ? meetingSignupScreenMutation
          : meetingCancelSignupScreenMutation;

      await apolloClient
        .mutate({
          mutation: mutation,
          variables: {
            subscription,
            profile,
          },
        })
        .then(async () => {
          await profilesStore.setPinValidation({
            validating: false,
            validPin: true,
          });

          this.getSelectedMeeting(subscription.id);
        })
        .catch((error) => {
          profilesStore.setPinValidation({
            validating: false,
            validPin: false,
          });

          throw new Error('Error signing up to meeting', error);
        });
    },
    setSuggestion(suggestion) {
      this.suggestion = suggestion;
    },
    async meetingSuggest({ profile, meetingId }) {
      await apolloClient
        .mutate({
          mutation: meetingSuggestMutation,
          variables: {
            profile,
            meetingId,
            suggestion: this.suggestion,
          },
        })
        .then(() => {
          this.getSelectedMeeting(meetingId);
        })
        .catch((error) => {
          throw new Error('Error saving suggestion', error);
        });
    },
  },
});
