import gql from 'graphql-tag';

export const shiftDeleteMutation = gql`
  mutation shift($id: ID!) {
    shift {
      delete(id: $id) {
        id
        message
        success
      }
    }
  }
`;
