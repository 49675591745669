<script setup>
import { getModuleData } from '@utils/modules';
import { onBeforeMount, ref } from 'vue';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';
import { useActivitiesStore } from '@stores/activities';
import { useCacheStore } from '@stores/cache';

const cache = useCacheStore();
const activitiesStore = useActivitiesStore();

const isLoading = ref(true);

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().ACTIVITIES;

onBeforeMount(async () => {
  await cache.use(
    'activitiesStore.getActivities',
    activitiesStore.getActivities
  );
  isLoading.value = false;
});
</script>

<template>
  <div>
    <ModuleBaseView
      v-if="!isLoading"
      :moduleName="emptyKey"
      :moduleData="activitiesStore.internal"
      :colorHex="colorHex"
      :icon="icon"
      :emptyKey="emptyKey"
      :routeName="routeName"
      :entryRouterName="entryRouterName"
    />
  </div>
</template>
