import gql from 'graphql-tag';

export const profilesQuery = gql`
  query profiles($filter: Filter!) {
    profiles(filter: $filter) {
      ... on Employee {
        type
        id
        displayImage
        birthday
        showBirthday
        description
        title
        phone
        mobile
        showMobile
        email
        showEmail
        showImage
        displayName
        firstName
        departments {
          id
          name
        }
      }
      ... on Resident {
        type
        id
        displayImage
        birthday
        showBirthday
        description
        mobile
        showMobile
        email
        showEmail
        showImage
        displayName
        firstName
        homeDays
        departments {
          id
          name
        }
      }
    }
  }
`;
