import { defineStore } from 'pinia';
import { apolloClient } from '@utils/apollo';
import { useDepartmentStore } from '@stores/department';
import { cloneDeep } from 'lodash';
import { format } from '@utils/date-fns';
import { mealRangeQuery } from '@graphql/queries/mealRange.js';
import { pcdMealsQuery } from '@graphql/queries/pcdMeals.js';
import { mealEntryQuery } from '@graphql/queries/mealEntry.js';
import { mealSignupScreenMutation } from '@graphql/mutations/mealSignupScreen.js';
import { mealCancelSignupScreenMutation } from '@graphql/mutations/mealCancelSignupScreen.js';
import { useInstitutionStore } from '@stores/institution';
import { useProfilesStore } from '@stores/profiles';

export const useMealsStore = defineStore('meals', {
  state: () => ({
    all: [],
    selected: {},
  }),
  actions: {
    async getMeals() {
      const departmentStore = useDepartmentStore();

      if (!departmentStore.id) {
        throw new Error('No department id provided');
      }

      const dateNow = new Date();
      const daysForward = departmentStore.settings?.meal?.daysForward || 90;

      const dateStart = dateNow.setDate(dateNow.getDate() + daysForward - 1); // subtract one as we should include today's date
      const dateEnd = new Date(dateStart).toISOString().split('T')[0];

      await apolloClient
        .query({
          query: mealRangeQuery,
          variables: {
            filter: {
              departments: departmentStore.id,
            },
            dateFrom: new Date().toISOString().split('T')[0],
            dateTo: dateEnd,
            timezone: 'Europe/Copenhagen',
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          this.all = response.data.mealRange;
        })
        .catch((error) => {
          console.log('Error getting meals', error);
        });
    },
    async getPcdMeals() {
      const departmentStore = useDepartmentStore();

      if (!departmentStore.id) {
        throw new Error('No department id provided');
      }

      await apolloClient
        .query({
          query: pcdMealsQuery,
          fetchPolicy: 'no-cache',
          variables: {
            date: format(new Date(), 'yyyy-MM-dd'),
          },
        })
        .then((response) => {
          const meals = response.data.pcdMeals.reduce((acc, meal) => {
            const courses = meal.courses.map((course) => {
              return {
                isPcd: true,
                id: course.id,
                title: course.title,
                category: course.category,
                startDate: meal.date,
                coverImage: {
                  source: '../../assets/img/external/external-meal.jpg',
                },
              };
            });

            acc.push(...courses);
            return acc;
          }, []);

          this.all = meals;
        })
        .catch((error) => {
          console.log('Error getting meals', error);
        });
    },
    async getSelectedMeal(mealId) {
      const institutionStore = useInstitutionStore();

      if (institutionStore.settings.useExternalMeals) {
        const meal = this.all.find((meal) => meal.id === mealId);
        this.selected = meal;
        return;
      }

      this.selected = {};

      await apolloClient
        .query({
          query: mealEntryQuery,
          variables: {
            id: mealId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          this.selected = response.data.mealEntry;
        })
        .catch((error) => {
          console.log('Could not get meal entry: ', error);
        });
    },
    async mealSignupScreen({ subscription, profile, opt }) {
      const profilesStore = useProfilesStore();
      const mutation =
        opt === 'in'
          ? mealSignupScreenMutation
          : mealCancelSignupScreenMutation;

      await apolloClient
        .mutate({
          mutation: mutation,
          variables: {
            subscription,
            profile,
          },
        })
        .then(async () => {
          profilesStore.setPinValidation({
            validating: false,
            validPin: true,
          });

          this.getSelectedMeal(subscription.id);
        })
        .catch((error) => {
          profilesStore.setPinValidation({
            validating: false,
            validPin: false,
          });

          throw new Error('Error signing up to meal', error);
        });
    },
    async updateParticipants(mealId) {
      await apolloClient
        .query({
          query: mealEntryQuery,
          variables: {
            id: mealId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          const participants = response.data.mealEntry.participants;

          const allMeals = cloneDeep(this.all);
          const mealIndex = allMeals.findIndex((meal) => meal.id === mealId);

          if (mealIndex > -1) {
            allMeals[mealIndex].participants = participants;
            this.all = allMeals;
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
        });
    },
  },
});
