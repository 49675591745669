import { defineStore } from 'pinia';
import { apolloClient } from '@utils/apollo';
import constants from '@utils/constants.js';
import { profilesQuery } from '@graphql/queries/profiles.js';
import { myArchiveScreenQuery } from '@graphql/queries/myArchiveScreen.js';
import { userQuery } from '@graphql/queries/user.js';
import { useInstitutionStore } from '@stores/institution';

export const useProfilesStore = defineStore('profiles', {
  state: () => ({
    all: [],
    activeProfile: {},
    myArchive: {},
    pinValidation: {},
  }),
  actions: {
    async getProfiles() {
      const institutionStore = useInstitutionStore();

      const departmentIds = institutionStore.departments.map(
        (department) => department.id
      );

      await apolloClient
        .query({
          query: profilesQuery,
          variables: {
            filter: {
              departments: departmentIds,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          const profiles = response.data.profiles.map((profile) => {
            if (!profile.showImage) {
              profile.displayImage = constants.avatarPlaceholder;
            }
            return profile;
          });

          this.all = profiles;
        })
        .catch((error) => {
          console.log('Error getting profiles', error);
        });
    },
    setActiveProfile(activeProfile) {
      this.activeProfile = activeProfile;
    },
    async getMyArchive({ id, type }) {
      await apolloClient
        .query({
          query: myArchiveScreenQuery,
          variables: {
            profile: {
              profileType: type,
              profileId: id,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          this.myArchive = response.data.myArchiveScreen;
        })
        .catch((error) => {
          console.log('Error getting my archive', error);
        });
    },
    setPinValidation(pinValidation) {
      this.pinValidation = pinValidation;
    },
    async validatePin({ profile, pin }) {
      this.pinValidation = {
        validating: true,
        validPin: false,
        pin,
      };
      await apolloClient
        .query({
          query: userQuery,
          variables: {
            profile: {
              pin: pin.toString(),
              profileId: profile.id,
              profileType: profile.type,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          this.setPinValidation({
            validating: false,
            validPin: response.data.user.validatePin.success,
            pin,
          });
        })
        .catch((error) => {
          console.log('Error validating pin', error);
          this.setPinValidation({
            validating: false,
            validPin: false,
          });
        });
    },
  },
});
