import gql from 'graphql-tag';

export const pcdMealsQuery = gql`
  query pcdMeals($date: Date) {
    pcdMeals(date: $date) {
      date
      courses {
        id
        title
        category
      }
    }
  }
`;
