<script setup>
import { onBeforeMount, ref } from 'vue';
import { getModuleData } from '@utils/modules';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';
import { useNewsStore } from '@stores/news';
import { useCacheStore } from '@stores/cache';

const newsStore = useNewsStore();
const cache = useCacheStore();
const isLoading = ref(true);

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().NEWS;

onBeforeMount(async () => {
  await cache.use('newsStore.getNews', newsStore.getNews);
  isLoading.value = false;
});
</script>

<template>
  <ModuleBaseView
    v-if="!isLoading"
    :moduleName="emptyKey"
    :moduleData="newsStore.all"
    :colorHex="colorHex"
    :icon="icon"
    :emptyKey="emptyKey"
    :routeName="routeName"
    :entryRouterName="entryRouterName"
    :shouldShowInfoTag="false"
    :shouldShowDate="false"
    :shouldShowSectionDivider="false"
  />
</template>
