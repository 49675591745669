<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { capitalize } from 'lodash';
import { format } from '@utils/date-fns';
import { useInstitutionStore } from '@stores/institution';

const institutionStore = useInstitutionStore();

const intervalId = ref(null);
const date = ref(new Date());

onMounted(() => {
  intervalId.value = setInterval(() => {
    date.value = new Date();
  }, 60000); // 60 seconds.
});

onBeforeUnmount(() => {
  clearInterval(intervalId.value);
});

const time = computed(() => {
  return format(date.value, 'H:mm');
});

const weekday = computed(() => {
  return capitalize(format(date.value, 'EEEE'));
});

const dateAndMonth = computed(() => {
  return format(date.value, 'do LLLL');
});
</script>

<template>
  <header
    class="flex items-center justify-between border-b-2 border-neutral-300 z-75 bg-white py-6"
  >
    <div class="flex flex-1 justify-start" style="padding-left: 30">
      <span class="h900">{{ time }}</span>
    </div>

    <div class="flex-none">
      <img class="logo h-20 w-auto" :src="institutionStore.logo.source" />
    </div>

    <div class="flex flex-1 flex-col items-end" style="padding-right: 30">
      <div class="h700">{{ weekday }}</div>
      <div class="h600">{{ dateAndMonth }}</div>
    </div>
  </header>
</template>

<style lang="scss">
header {
  .logo {
    max-height: 120px;
  }
}
</style>
