<script setup>
import { getModuleData } from '@utils/modules';
import { onBeforeMount, ref } from 'vue';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';
import { useMeetingsStore } from '@stores/meetings';
import { useCacheStore } from '@stores/cache';

const meetingsStore = useMeetingsStore();
const cache = useCacheStore();

const isLoading = ref(true);

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().MEETINGS;

onBeforeMount(async () => {
  await cache.use('meetingsStore.getMeetings', meetingsStore.getMeetings);
  isLoading.value = false;
});
</script>

<template>
  <ModuleBaseView
    v-if="!isLoading"
    :moduleName="emptyKey"
    :moduleData="meetingsStore.all"
    :colorHex="colorHex"
    :icon="icon"
    :emptyKey="emptyKey"
    :routeName="routeName"
    :entryRouterName="entryRouterName"
  />
</template>
