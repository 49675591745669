<script setup>
import { getModuleData } from '@utils/modules';
import { computed, watch, onUnmounted, onBeforeMount, ref } from 'vue';
import { useGalleriesStore } from '@stores/galleries';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';
import { useCacheStore } from '@stores/cache';

const galleriesStore = useGalleriesStore();
const cache = useCacheStore();
const isLoading = ref(true);

const galleries = computed(() => galleriesStore.all);
const selectedCategory = computed(() => galleriesStore.selectedCategory);

const shouldShowSectionDivider = computed(
  () => selectedCategory.value !== 'AllCategoriesSelected'
);

watch(galleries, (newValue, oldValue) => {
  if (newValue && newValue !== oldValue) {
    getCategories(newValue);
  }
});

onBeforeMount(async () => {
  await cache.use('galleriesStore.getGalleries', galleriesStore.getGalleries);
  getCategories(galleries.value);
  isLoading.value = false;
});

onUnmounted(() => {
  galleriesStore.setCategories([]);
});

const groupByKey = computed(() => {
  return galleriesStore.categories.length
    ? 'category'
    : 'AllCategoriesSelected';
});

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().GALLERY;

function getCategories(galleries) {
  const categories = [
    ...new Set(
      galleries
        .filter((gallery) => gallery.category)
        .map((gallery) => gallery.category.title)
        .sort((a, b) => a.localeCompare(b))
    ),
  ];

  galleriesStore.setCategories(categories);
}
</script>

<template>
  <ModuleBaseView
    v-if="!isLoading"
    :moduleName="emptyKey"
    :moduleData="galleries"
    :colorHex="colorHex"
    :icon="icon"
    :emptyKey="emptyKey"
    :routeName="routeName"
    :entryRouterName="entryRouterName"
    :shouldShowMediaCount="true"
    :groupByKey="groupByKey"
    :filter="selectedCategory"
    :shouldShowSectionDivider="shouldShowSectionDivider"
  />
</template>
