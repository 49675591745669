import { defineStore } from 'pinia';
import { apolloClient } from '@utils/apollo';
import { useDepartmentStore } from '@stores/department';
import { isAfter, isBefore, endOfDay, subDays, addDays } from 'date-fns';
import { format } from '@utils/date-fns';
import { toDate } from 'date-fns-tz';
import { get } from 'lodash';
import { scheduleQuery } from '@graphql/queries/schedule.js';
import { shiftUpdateMutation } from '@graphql/mutations/shiftUpdate.js';
import { shiftDeleteMutation } from '@graphql/mutations/shiftDelete.js';
import * as Sentry from '@sentry/vue';
import { useInstitutionStore } from '@stores/institution';
import { useProfilesStore } from '@stores/profiles';

const getFilteredShifts = (schedule, filter) => {
  return schedule
    .filter((shift) => {
      if (filter === 'later') {
        if (
          isAfter(new Date(shift.start), new Date()) &&
          isBefore(new Date(shift.start), endOfDay(new Date()))
        )
          return true;
        return false;
      }

      if (isAfter(new Date(), new Date(shift.end))) return false;
      if (isAfter(new Date(shift.start), new Date())) return false;
      return true;
    })
    .map((shift) => {
      return {
        ...shift,
        start: toDate(shift.start, { timeZone: 'Europe/Copenhagen' }),
        end: toDate(shift.end, { timeZone: 'Europe/Copenhagen' }),
      };
    });
};

const getRange = () => {
  const institutionStore = useInstitutionStore();
  const departmentStore = useDepartmentStore();

  const matchingDepartment = institutionStore.settings.shift?.find(
    ({ departmentId }) => departmentId == departmentStore.id
  );
  const now = new Date();

  // Subtract 1 because the core admin setting is not 0 based but 1 should mean 1 days forward.
  const daysForward =
    get(
      matchingDepartment,
      'daysForward',
      departmentStore.settings.shift.daysForward
    ) - 1;

  return {
    from: subDays(now, daysForward),
    to: addDays(now, daysForward),
  };
};

export const useWorkScheduleStore = defineStore('workSchedule', {
  state: () => ({
    all: [],
    workingNow: [],
    comingLater: [],
    isLoading: false,
    type: '',
    selectedShift: {},
    editAction: '',
    replaceWithEmployeeId: null,
  }),
  actions: {
    async getWorkSchedules() {
      const departmentStore = useDepartmentStore();
      const range = getRange();

      await apolloClient
        .query({
          query: scheduleQuery,
          variables: {
            filter: {
              departments: departmentStore.id,
            },
            dateFrom: format(range.from, 'yyyy-MM-dd'),
            dateTo: format(range.to, 'yyyy-MM-dd'),
            timezone: 'Europe/Copenhagen',
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          const shifts = get(response, 'data.schedule.shifts', []);

          this.all = shifts;
          this.comingLater = getFilteredShifts(shifts, 'later');
          this.workingNow = getFilteredShifts(shifts, 'now');

          if (this.selectedShift?.id) {
            const updatedShift = shifts.find((shift) => {
              return shift?.id === this.selectedShift?.id;
            });

            this.selectedShift = updatedShift;
          }
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: {
              departmentId: departmentStore.id,
            },
          });
        });
    },
    async shiftUpdate(shiftData) {
      const profilesStore = useProfilesStore();

      await apolloClient
        .mutate({
          mutation: shiftUpdateMutation,
          variables: {
            shift: {
              id: this.selectedShift.id,
              employeeId:
                this.replaceWithEmployeeId || this.selectedShift.employee.id,
              cancelled: shiftData.cancel,
            },
          },
        })
        .then(async () => {
          this.getWorkSchedules(shiftData.dates);

          this.selectedShift = this.all.find(
            (shift) => shift.id === this.selectedShift.id
          );

          await profilesStore.setPinValidation({
            validating: false,
            validPin: undefined,
          });
        })
        .catch((error) => {
          profilesStore.setPinValidation({
            validating: false,
            validPin: undefined,
          });
          throw new Error('Error updating shift', error);
        });
    },
    async shiftDelete(shiftData) {
      const profilesStore = useProfilesStore();

      await apolloClient
        .mutate({
          mutation: shiftDeleteMutation,
          variables: {
            id: shiftData.shiftId,
          },
        })
        .then(async () => {
          this.getWorkSchedules(shiftData.dates);

          await profilesStore.setPinValidation({
            validating: false,
            validPin: false,
          });
        })
        .catch((error) => {
          profilesStore.setPinValidation({
            validating: false,
            validPin: false,
          });
          throw new Error('Error deleting shift', error);
        });
    },
    setSelectedShift(shift) {
      this.selectedShift = shift;
    },
    setReplaceWithEmployeeId(id) {
      this.replaceWithEmployeeId = id;
    },
    setEditAction(action) {
      this.editAction = action;
    },
  },
});
