import gql from 'graphql-tag';

export const mealSignupScreenMutation = gql`
  mutation mealSignupScreen(
    $profile: ScreenProfile!
    $subscription: MealSubscription!
  ) {
    mealSignupScreen(profile: $profile, subscription: $subscription) {
      id
      message
      success
    }
  }
`;
