import gql from 'graphql-tag';

export const activityCancelSignupScreenMutation = gql`
  mutation activityCancelSignupScreen(
    $profile: ScreenProfile!
    $subscription: ActivitySubscription!
  ) {
    activityCancelSignupScreen(profile: $profile, subscription: $subscription) {
      id
      success
      message
    }
  }
`;
