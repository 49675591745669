<script setup>
import getTTS from '@composables/tts.js';
import { ref, computed, onMounted, onUnmounted } from 'vue';

defineProps({
  entityId: {
    type: String,
    default: '',
  },
  entityType: {
    type: String,
    default: '',
  },
});

const audioEnabled = ref(false);
const audioPlaying = ref(false);
const tts = ref('');

onMounted(async () => {
  const text = getTextByAriaLabel();
  const { error, data } = await getTTS(text);

  if (error.value) {
    return;
  }

  tts.value = data.value;
});

onUnmounted(() => {
  audioSource.value.pause();
  audioSource.value.currentTime = 0;
  audioSource.value.removeEventListener('ended', () => {
    audioPlaying.value = false;
  });
});

const audioSource = computed(() => {
  return new Audio(tts.value.source);
});

const backgroundColor = computed(() => {
  return audioPlaying.value ? 'bg-orange' : 'bg-white';
});

function playAudio() {
  audioEnabled.value = !audioEnabled.value;

  if (audioEnabled.value) {
    audioSource.value.play();
    audioPlaying.value = true;
  } else {
    audioSource.value.pause();
    audioSource.value.currentTime = 0;
    audioPlaying.value = false;
  }

  audioSource.value.addEventListener(
    'ended',
    () => (audioPlaying.value = false)
  );
}

function getTextByAriaLabel() {
  const parentElement = document.getElementsByClassName('tts-content')[0];
  const nodeList = parentElement.querySelectorAll('[aria-label]');

  const strings = [];
  for (let i = 0; i < nodeList.length; i++) {
    strings.push(nodeList[i].ariaLabel);
  }

  return strings.join('. ');
}
</script>

<template>
  <div class="mediaIcons absolute -top-28 left-10 h-20 flex justify-end">
    <div
      class="rounded-full w-20 h-20 flex justify-center items-center relative duration-300 transition"
      :class="backgroundColor"
      @click="playAudio"
    >
      <fa-icon
        v-if="!audioPlaying"
        :icon="['fal', 'volume']"
        class="text-4xl"
      />
      <div v-else class="sound-wave flex justify-around items-center">
        <i class="bar" />
        <i class="bar" />
        <i class="bar" />
        <i class="bar" />
        <i class="bar" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped="true">
.sound-wave {
  width: 50px;
  height: 65px;

  .bar {
    width: 6px;
    margin-right: 1px;
    height: 65px;
    background: #ffffff;
    animation: sound 0ms -800ms linear infinite alternate;
    transition: height 0.4s;
    border-radius: 10px;
  }
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 40px;
  }
}

.bar:nth-child(1) {
  animation-duration: 374ms;
}
.bar:nth-child(2) {
  animation-duration: 333ms;
}
.bar:nth-child(3) {
  animation-duration: 307ms;
}
.bar:nth-child(4) {
  animation-duration: 358ms;
}
.bar:nth-child(5) {
  animation-duration: 300ms;
}
</style>
