<script setup>
import { computed, onMounted, watch } from 'vue';
import { useIdle } from '@vueuse/core';
import { useDepartmentStore } from '@stores/department';
import { useIdleHandlerStore } from '@stores/idleHandler';

const departmentStore = useDepartmentStore();
const idleHandlerStore = useIdleHandlerStore();
/**
 * Creates a watcher to set idle status is store, when idle time is exceeded.
 * We watch the idle component in createIdleWatcher, and try to reset the store value, which is an abstraction and handles our own business logic,
 * to decide when we pause idle checks or not.
 *
 * We reset the local idle watcher timer here, for example, to prevent, screen from staying in the same idle state over night, causing data to not be refetched.
 */

const idleTimeout = computed(() => {
  return departmentStore.settings.screenConfig.timeout;
});

onMounted(() => {
  createIdleWatcher(idleTimeout.value);
});

watch(idleTimeout, (newTimeout) => {
  createIdleWatcher(newTimeout);
});

// Create an idle object using useIdle and setup a watcher on the idle object's value to update the state when it changes.
function createIdleWatcher(timeout) {
  const timeoutMS = timeout * 1000; // BE uses seconds and useIdle uses ms.
  const { idle, reset } = useIdle(timeoutMS);

  watch(
    () => idle.value,
    (to) => {
      idleHandlerStore.setIdle(to);

      // Reset will refresh the idle state, briefly setting state true then false, causing screen to only be in the idle shortly,
      // triggering business logic that reacts to the idle state switching.
      reset();
    }
  );
}
</script>

<template>
  <div></div>
</template>
