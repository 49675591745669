import gql from 'graphql-tag';

export const meetingEntryQuery = gql`
  query meetingEntry($id: ID!) {
    meetingEntry(id: $id) {
      ... on SingleMeeting {
        agendaPoints {
          id
          picture {
            blurhash
            id
            source
          }
          title
        }
        cancelled
        category
        coverImage {
          blurhash
          id
          source
        }
        departments {
          color
          id
          name
        }
        endDate
        id
        institution {
          color
          name
        }
        location
        participants {
          ... on Employee {
            id
            displayName
            displayImage
            showImage
            departments {
              name
              color
            }
          }
          ... on Resident {
            id
            displayName
            displayImage
            showImage
            departments {
              name
              color
            }
          }
        }
        participating
        signupPossible
        startDate
        suggestions {
          id
          suggester {
            ... on Employee {
              id
              displayName
              displayImage
              showImage
            }
            ... on Resident {
              id
              displayName
              displayImage
              showImage
            }
          }
          title
        }
        title
      }
      ... on RepeatingMeeting {
        agendaPoints {
          id
          picture {
            blurhash
            description
            id
            inMyArchive
            source
          }
          title
        }
        cancelled
        category
        title
        coverImage {
          blurhash
          id
          source
        }
        departments {
          color
          id
          name
        }
        endDate
        id
        location
        participants {
          ... on Employee {
            id
            displayName
            displayImage
            showImage
          }
          ... on Resident {
            id
            displayName
            displayImage
            showImage
          }
        }
        participating
        repeat {
          description
          rrule
          rruleText
        }
        signupPossible
        startDate
        suggestions {
          id
          suggester {
            ... on Employee {
              id
              displayName
              displayImage
              showImage
            }
            ... on Resident {
              id
              displayName
              displayImage
              showImage
            }
          }
          title
        }
      }
    }
  }
`;
