import gql from 'graphql-tag';

export const mealCancelSignupScreenMutation = gql`
  mutation mealCancelSignupScreen(
    $profile: ScreenProfile!
    $subscription: MealSubscription!
  ) {
    mealCancelSignupScreen(profile: $profile, subscription: $subscription) {
      id
      message
      success
    }
  }
`;
