import gql from 'graphql-tag';

export const userQuery = gql`
  query user($profile: ScreenProfile) {
    user {
      validatePin(profile: $profile) {
        id
        message
        success
      }
    }
  }
`;
