import { defineStore } from 'pinia';
import { apolloClient } from '@utils/apollo';
import { useDepartmentStore } from '@stores/department';
import { newsQuery } from '@graphql/queries/news.js';

export const useNewsStore = defineStore('news', {
  state: () => ({
    all: [],
  }),
  actions: {
    async getNews() {
      const departmentStore = useDepartmentStore();

      if (!departmentStore.id) {
        throw new Error('No department id provided');
      }

      await apolloClient
        .query({
          query: newsQuery,
          variables: {
            filter: {
              departments: departmentStore.id,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          this.all = response.data.news;
        })
        .catch((error) => {
          console.log('Error getting news', error);
        });
    },
  },
});
