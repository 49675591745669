import gql from 'graphql-tag';

export const departmentQuery = gql`
  query department($id: ID!) {
    department(id: $id) {
      color
      icon
      id
      institutionId
      name
      loginKey
      presentation {
        description
        image
      }
      settings {
        meal {
          daysForward
          signup
        }
        shift {
          daysForward
        }
        screenConfig {
          footerConfig
          carouselDuration
          timeout
          gridSize
          departmentSwitcher
          modules {
            colSpan
            id
            rowSpan
            type
          }
        }
        simpleSchedulePlan {
          starttime
          endtime
          icon
          name
        }
        slideshow {
          duration
        }
      }
    }
  }
`;
