<script setup>
import { ref, onMounted } from 'vue';
import i18n from '@i18n';
import { capitalize } from 'lodash';
import ChipSelector from '@components/shared/ChipSelector.vue';
import { useGalleriesStore } from '@stores/galleries';

const galleriesStore = useGalleriesStore();

const scrollWidth = ref(null);
const clientWidth = ref(null);
const ALL_CATEGORIES_SELECTED = 'AllCategoriesSelected';

onMounted(() => {
  setTimeout(() => {
    const container = document.getElementsByClassName('container')[0];
    scrollWidth.value = container?.scrollWidth;
    clientWidth.value = container?.clientWidth;
  }, 2000);
});

function categorySelected(category) {
  galleriesStore.setSelectedCategory(category);
}
</script>

<template>
  <div
    class="relative z-20 flex"
    :class="{ 'justify-center': scrollWidth >= clientWidth }"
  >
    <ChipSelector
      :class="{ 'mr-4': scrollWidth > clientWidth }"
      :title="capitalize(i18n.global.t('global.all'))"
      :index="0"
      :selected="galleriesStore.selectedCategory === ALL_CATEGORIES_SELECTED"
      @click="categorySelected(ALL_CATEGORIES_SELECTED)"
    />
    <div v-if="scrollWidth > clientWidth" class="w-[2px] h-12 bg-neutral-300" />

    <div class="container w-fit flex overflow-x-scroll z-10 h-12">
      <ChipSelector
        class="ml-4"
        v-for="(category, index) in galleriesStore.categories"
        :key="category"
        :index="index + 1"
        :title="category"
        :selected="galleriesStore.selectedCategory === category"
        @click="categorySelected(category)"
      />
      <div v-if="scrollWidth > clientWidth" class="shadow" />
    </div>
  </div>
</template>

<style>
.shadow {
  box-shadow: inset -14px 0px 14px 0px rgb(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  pointer-events: none;
}
</style>
