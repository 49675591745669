<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { isEmpty } from 'lodash';
import { default as HeaderEl } from '@components/shared/Header.vue';
import { default as FooterEl } from '@components/footer/Footer.vue';
import Overlay from '@views/overlays/Overlay.vue';
import DimmerOverlay from '@components/dimmer/DimmerOverlay.vue';
import i18n from '@i18n';
import IdleWatcher from '@utils/idleWatcher.vue';
import auth from '@utils/auth';
import constants from '@utils/constants.js';
import { useInstitutionStore } from '@stores/institution';
import { useDepartmentStore } from '@stores/department';
import { useCacheStore } from '@stores/cache';
import { useProfilesStore } from '@stores/profiles';
import { useGeneralStore } from '@stores/general';
import { useGalleriesStore } from '@stores/galleries';
import { useIdleHandlerStore } from '@stores/idleHandler';

const departmentStore = useDepartmentStore();
const institutionStore = useInstitutionStore();
const profilesStore = useProfilesStore();
const cache = useCacheStore();
const generalStore = useGeneralStore();
const galleriesStore = useGalleriesStore();
const idleHandlerStore = useIdleHandlerStore();
const route = useRoute();
const router = useRouter();

const showOverlay = ref(false);

const isIdle = computed(() => idleHandlerStore.isIdle);

const onStartPage = computed(() => {
  return route.path === '/';
});

const overlayHasValue = computed(() => {
  return !isEmpty(generalStore.activeOverlay);
});

generalStore.$subscribe((mutation, state) => {
  if (!isEmpty(state.activeOverlay)) {
    showOverlay.value = true;
  }
});

watch(isIdle, async (newValue) => {
  const mainLoginKey = sessionStorage.getItem('main_login_key');
  const tempLoginKey = sessionStorage.getItem('temp_login_key');

  // If theres a difference we are currently not on the main department so switch back.
  if (tempLoginKey && mainLoginKey !== tempLoginKey) {
    await departmentStore.switchDepartment();
    return;
  }

  galleriesStore.setSelectedCategory('');

  if (route.path !== '/') {
    router.push('/');
    return;
  }

  if (newValue === true) {
    // force the widgets to re-render
    generalStore.setIdle();
  }
});

watch(route, async (newValue) => {
  nextTick(() => {
    closeOverlay(true);
    if (newValue.meta.scrollUp) {
      scrollToContent('scroll-up', 1200);
    } else {
      scrollToContent('route-wrapper', 150);
    }

    if (newValue.fullPath === '/') {
      profilesStore.setActiveProfile({});
    }
  });
});

onMounted(async () => {
  await auth.authenticate();

  try {
    await cache.use(
      'institutionStore.getInstitution',
      institutionStore.getInstitution
    );

    generalStore.setIdle();
    generalStore.setCurrentDate(new Date());

    await cache.use(
      'departmentStore.getDepartment',
      departmentStore.getDepartment
    );

    institutionStore.getExternalConnections();

    cache.use('profilesStore.getProfiles', profilesStore.getProfiles);

    i18n.global.locale.value = institutionStore.locale;
  } catch (err) {
    throw new Error(
      'Could not get institution / department data, retrying',
      err
    );
  }

  const DIMENSION_INSTITUTION = 1;
  const DIMENSION_DEPARTMENT = 2;

  window._paq.push([
    'setCustomDimension',
    DIMENSION_INSTITUTION,
    institutionStore.id,
  ]);
  window._paq.push([
    'setCustomDimension',
    DIMENSION_DEPARTMENT,
    departmentStore.id,
  ]);

  generalStore.setLoading(false);
});

async function closeOverlay(force = false) {
  idleHandlerStore.setPaused(false); // Always start idle checks whenever overlay closes.

  if (force) {
    await generalStore.setActiveOverlay({});
    await generalStore.setNextActiveOverlay({});
    await profilesStore.setPinValidation({});
    await generalStore.setActivePinFlow('');
  }

  const hasNextOverlay = !isEmpty(generalStore.nextActiveOverlay);
  showOverlay.value = false;

  if (hasNextOverlay) {
    setTimeout(() => {
      generalStore.setActiveOverlay(generalStore.nextActiveOverlay);
      showOverlay.value = true;
      generalStore.setNextActiveOverlay({});
    }, constants.animationDuration); // wait for the transition to end before we change the store value
  }
}

function scrollToContent(className, xValue) {
  const selector = document.querySelector(`.${className}`);
  if (selector) selector.scrollTop = xValue;
}
</script>

<template>
  <div
    id="app"
    :class="{
      isLoading: generalStore.isLoading,
      'bg-neutral-100': onStartPage,
    }"
    class="flex flex-col relative"
  >
    <HeaderEl />
    <div
      v-if="!generalStore.isLoading"
      class="flex-1 relative overflow-y-scroll overflow-x-hidden route-wrapper"
    >
      <router-view :key="$route.path" class="bg-white" />
    </div>

    <FooterEl @close-overlay="closeOverlay" />

    <Overlay
      :show="overlayHasValue && showOverlay"
      @close-overlay="closeOverlay"
    />
    <DimmerOverlay v-if="onStartPage" />
    <IdleWatcher v-if="departmentStore.settings.screenConfig.timeout > 0" />
  </div>
</template>

<style lang="scss">
.tick-credits {
  display: none !important;
  opacity: 0 !important;
}

#app {
  width: 1080px;
  height: 1920px;
  margin: auto;
  background: 'white';

  .content-views {
    transition: opacity 500ms;
  }

  .route-wrapper {
    .contentArea {
      animation-duration: 1.3s;

      .largeListItemHeader {
        border-radius: 25px 25px 0 0;
      }
    }

    &.scroll-up .contentArea {
      margin-top: 1200px;
    }
  }
}
</style>
