<script setup>
import ModuleNavBar from './ModuleNavBar.vue';
import IBGLogo from '@components/IBGLogo.vue';
import DisplayModeToggle from '@components/DisplayModeToggle.vue';
import IconButton from '@components/shared/IconButton.vue';
import DimmerButton from '@components/dimmer/DimmerButton.vue';
import DepartmentSwitcher from '@components/shared/DepartmentSwitcher.vue';
import CategorySelector from '@components/shared/CategorySelector.vue';
import { get, isEmpty } from 'lodash';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGeneralStore } from '@stores/general';
import { useDepartmentStore } from '@stores/department';
import { useGalleriesStore } from '@stores/galleries';

const generalStore = useGeneralStore();
const route = useRoute();
const router = useRouter();
const departmentStore = useDepartmentStore();
const galleriesStore = useGalleriesStore();
const emit = defineEmits(['close-overlay']);

const hasActiveOverlay = computed(() => {
  return !isEmpty(generalStore.activeOverlay);
});

const useGlobalDepartmentSwitcher = computed(() => {
  const settingEnabled = get(
    departmentStore.settings,
    'screenConfig.departmentSwitcher',
    false
  );
  const hasTempKey = sessionStorage.getItem('temp_login_key');

  return settingEnabled || hasTempKey;
});

// Can toggle view if both list and grid is available.
const canToggleListView = computed(() => {
  const contentViews = route.meta.contentViews;
  return (
    contentViews &&
    contentViews.some((view) => view.name === 'list') &&
    contentViews.some((view) => view.name === 'grid')
  );
});

const closeOverlayOrBack = () => {
  hasActiveOverlay.value ? emit('close-overlay', true) : router.back();
};

// Sets the contentView to handle cardType & positioning and activeViewType to handle list or grid view.
async function handleContentAndViewChange(viewChangedTo) {
  // Get the full contentView object that has been switched to then set store accordingly.
  const contentView = route.meta.contentViews.find(
    (view) => view.name === viewChangedTo
  );

  if (contentView) {
    await generalStore.setActiveContentView(contentView);

    if (viewChangedTo === 'list' || viewChangedTo === 'grid') {
      await generalStore.setActiveViewType(viewChangedTo);
    }
  } else {
    console.error('Changed contentView to non supported value:', viewChangedTo);
  }
}
</script>

<template>
  <div
    v-if="
      galleriesStore.categories?.length &&
      generalStore.activeViewType === 'list' &&
      route.path === '/galleries'
    "
    class="left-0 right-0 px-8 categories rounded-t-3xl relative bg-white shadow-topSmall py-8 pl-8 pr-0 pb-16"
  >
    <CategorySelector />
  </div>
  <div
    class="w-full relative rounded-t-3xl border-[3px] border-neutral-400 bg-neutral-300 z-40"
  >
    <!-- Toolbar -->
    <div
      class="flex flex-row items-center justify-between w-full absolute left-0 right-0 transform -translate-y-1/2 px-8"
    >
      <!-- Left container -->
      <div class="flex flex-1 justify-start">
        <IconButton
          v-show="route.path !== '/'"
          :icon="['fas', 'arrow-left']"
          :title="$t('global.backButton')"
          @click="closeOverlayOrBack()"
          capitalizeTitle
        />
      </div>

      <!-- Logo -->
      <IBGLogo class="flex-none" />

      <!-- Right container -->
      <div class="flex flex-1 justify-end">
        <DisplayModeToggle
          v-if="canToggleListView"
          @view-changed="handleContentAndViewChange"
        />

        <DimmerButton v-if="route.path === '/'" />

        <IconButton
          v-if="route.path === '/bookings'"
          class="pl-3"
          :icon="['far', 'fa-xmark']"
          @click="router.push('/bookingsList')"
        />
      </div>
    </div>

    <!-- Footer overlay/background -->
    <div
      class="flex flex-col justify-center pt-12"
      :style="{ height: useGlobalDepartmentSwitcher ? '208px' : '250px' }"
    >
      <ModuleNavBar />
    </div>
    <DepartmentSwitcher
      v-if="useGlobalDepartmentSwitcher && departmentStore.name"
    />
  </div>
</template>
