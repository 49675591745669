import { defineStore } from 'pinia';

/**
 * Handles the logic of setting idle to the correct value.
 *
 * If isPaused has been set to true, the isIdle updates will not be set to true, in order to
 * prevent screen to go idle, when user is playing slideshows.
 *
 * If isPaused is false, isIdle updates are allowed and the status can be set to true, to reset the screen.
 */

export const useIdleHandlerStore = defineStore('idleHandler', {
  state: () => ({
    isPaused: false,
    isIdle: false,
  }),
  actions: {
    setPaused(paused) {
      this.isPaused = paused;
    },
    setIdle(idle) {
      if (this.isPaused) {
        this.isIdle = false;
      } else {
        this.isIdle = idle;
      }
    },
  },
});
